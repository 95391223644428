import {React , useEffect , useState} from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Navbar from "../navbar/index";
import ContactUs from "../home/ContactUs";

const Layout = ({ children }) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (initialLoad) {
      // Check if there is a hash in the URL and scroll to the element
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        // If no hash, scroll to the top
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      setInitialLoad(false);
    }
  }, [location, initialLoad]);
  
  const navLinks = [
    { name: "Home", to: "/" },
    {
      name: "Products",
      to: "/#products",
      options: [
        { name: "Private Company Financials", to: "/products/cfs" },
        { name: "Private Equity Funding", to: "/products/funding" },
        {
          name: "Mergers and Acquisitions",
          to: "/products/mergers-acquisitions",
        },
        { name: "PE-VC Directory", to: "/products/directory" },
        { name: "Deal Digest", to: "/products/newsletter" },
        { name: "Ancillaries", to: "/products/custom-reports" },
      ],
    },
    {
      name: "League Tables",
      to: "/leagues",
    },
    {
      name: "What's New",
      to: "/#whats-new",
    },
    {
      name: "Company",
      to: "/#company",
      options: [
        { name: "About us", to: "/about" },
        { name: "Careers", to: "/careers" },
        { name: "Contact", to: "/contact" },
      ],
    },
  ];
  return (
    <div>
      <Navbar navLinks={navLinks} />
      {children}
      <ContactUs />
    </div>
  );
};

export default Layout;
