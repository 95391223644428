import React from "react";
import Container from "../layout/Container";
import league from "../../assets/assets/league2.jpg";
import Button from "../utils/Button";

const Leagues = () => {
  return (
    <Container className="flex flex-col lg:justify-center lg:items-center "> {/*h-[90vh] */}
      <div className="flex flex-col  gap-4 md:flex-row lg:h-[25rem] ">
        <div className="leading-5 sm:max-w-sm lg:max-w-md xl:max-w-lg">
          <p className="my-4 text-blue-500 text-[0.70rem] font-semibold sm:text-xs">
            VENTURE INTELLIGENCE LEAGUE TABLES
          </p>
          <h1 className="my-4 text-2xl leading-snug tracking-tighter md:text-3xl xl:text-4xl">
            <div className="italic headings font-medium leading-tight">
              Explore Our Exclusive
            </div>
            <div className=" font-[inital] font-bold">League Tables</div>
          </h1>
          <h2 className="text-gray-900 font-semibolds text-[1.10rem] tracking-tight leading-7">
            Venture Intelligence League Tables are the definitive source for
            tracking transactions involving India-based companies. Here's what
            makes them unique:
          </h2>
          <div className="flex flex-wrap gap-4 justify-around lg:justify-start">
            <Button className="my-4">Explore League Tables</Button>
          </div>
        </div>
        <div className="hidden lg:block lg:max-w-sm xl:max-w-md">
          <img src={league} alt="leagues" className="" />
        </div>
      </div>
    </Container>
  );
};

export default Leagues;
