import React, { useState } from "react";
import Container from "../layout/Container";
import Card from "../utils/PricingCard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
// import dummy from "../../assets/dummy.png";

const Products = () => {
  const products = [
    {
      type: "EQUITY",
      product: "PE-VC DEALS",
      summary: (
        <>
          <li>
            Investments since 1998 and exits since 2004 in India-based companies
            by private equity and venture capital firms.
          </li>
          <li>
            Investments by Strategic Investors which function similar to PE/VC
            firms and often co-invest with them.
          </li>
          <li>
            Includes sub-databases tracking Angel Investing, Social
            Venture/Impact Investing and Incubation/Acceleration activity.{" "}
          </li>
          <li>
            Profiles of PE & VC firms; PE/VC-backed companies and Companies
            incubated at various Incubators/Accelerators across India.
          </li>
          <li>
            Shareholding details - Comprehensive & Post Transaction Changes
          </li>
        </>
      ),
      path: "/products/pe-vc",
    },
    {
      type: "FINANCIALS",
      product: "COMPANY FINANCIAL SEARCH",
      summary: (
        <>
          <li>Private Company Financials data covering 50k+ companies</li>
          <li>
            Includes Profit & Loss, Balance Sheet, Cash Flow statements and
          </li>
          <li>
            Ratios, Company filings (as reported to MCA), Index of Charges Data
          </li>
          <li>Various Powerful filters to extract the data</li>
        </>
      ),
      path: "/#products/cfs",
    },
    {
      type: "MERGER & ACQUISITIONS",
      product: "MERGER & ACQUISITIONS",
      summary: (
        <>
          <li>
            Inbound, outbound and domestic deals involving India-based companies
          </li>
          <li> Enterprise Valuation and multiples</li>
          <li>Includes minority stake acquisitions</li>
        </>
      ),
      path: "/#products/ma",
    },
    {
      type: "EQUITY",
      product: "PE-REAL ESTATE",
      summary: (
        <>
          <li>
            Inbound, outbound and domestic deals involving India-based companies
          </li>
          <li>Enterprise Valuation and multiples,</li>
          <li>Includes minority stake acquisitions</li>
        </>
      ),
      path: "/#products/pe-re",
    },
    {
      type: "NEWSLETTER",
      product: "DEAL DIGEST",
      summary: (
        <>
          <li>
            Inbound, outbound and domestic deals involving India-based companies
          </li>
          <li>Enterprise Valuation and multiples,</li>
          <li>Includes minority stake acquisitions</li>
        </>
      ),
      path: "/#products/pe-re",
    },
    {
      type: "DIRECTORY",
      product: "PE-VC INVESTORS DIRECTORY",
      summary: (
        <>
          <li>The India Private Equity / Venture Capital Directory</li>
          <li>The India Private Equity-Real Estate Directory</li>
          <li>
            Directory of Investors from China, Japan, S.E. Asia Active in India
          </li>
          <li>Directory of Early Stage Investors in India</li>
          <li>Limited Partners Directory</li>
          <li>Family Office Directory</li>
        </>
      ),
      path: "/#products/pe-re",
    },
    {
      type: "Ancillaries",
      product: "REPORTS",
      summary: (
        <>
          <li>Series A Landscape Report</li>
          <li>Private Equity Impact Report</li>
        </>
      ),
      path: "/#products/pe-re",
    },
  ];

  const [activeProduct, setActiveProduct] = useState(0);

  const handleRightClick = () => {
    setActiveProduct((prevProduct) =>
      prevProduct === products.length - 1 ? 0 : prevProduct + 1
    );
  };

  const handleLeftClick = () => {
    setActiveProduct((prevProduct) =>
      prevProduct === 0 ? products.length - 1 : prevProduct - 1
    );
  };
  
  return (
    <div className="relative  sm:h-[60rem] lg:h-[45rem] product-cards" id="products">
      <Container className="py-4 product-cards">
        <h1 className="m-4 py-4 flex justify-center  text-2xl  font-bold xl:text-left ">PRODUCTS</h1>
        <div className="relative overflow-hidden p-2">
          <div
            className="flex flex-row  transition-transform duration-300 ease-in-out "
            style={{ transform: `translateX(-${activeProduct * 100}%)` }}
          >
            {products.map((product, index) => (
              <div key={index}
              className="w-full flex-shrink-0 px-4 flex justify-center gap-8 text-xl border-gray-200 rounded-lg">
                <Card key={index} {...product} />
              </div>
            ))}
          </div>
        </div>
        <button
          onClick={handleLeftClick}
          className="lg:top-[50%] circle top-[52%] text-black   md:block ml-0 md:top-2/4  p-2 hover:text-black  cursor-pointer absolute  left-2 md:left-20"
        >
          <FontAwesomeIcon icon={faChevronLeft} size="2xl" />
        </button>
        <button
          onClick={handleRightClick}
          className="lg:top-[50%] circle top-[52%] md:block  md:top-2/4 mr-0 text-black   p-2 hover:text-black cursor-pointer absolute  right-2 md:right-20"
        >
          <FontAwesomeIcon icon={faChevronRight} size="2xl" />
        </button>
      </Container>
    </div>
  );
};

export default Products;


