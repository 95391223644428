import React from "react";
import Button from "../utils/Button";
import Container from "../layout/Container";
import newletter from "../../assets/assets/newsletter.jpg";

const NewsLetter = () => {
  return (
    <div className="xl:w-full overflow-x-hidden">
      <Container >
        <div className="flex flex-col justify-center gap-4 md:flex-row lg:h-[25rem] items-center">
          <div className="leading-5 lg:max-w-xl xl:max-w-lg">
            <p className="my-4 text-blue-500 text-[0.70rem] font-semibold sm:text-xs">
              DEAL DIGEST
            </p>
            <h1 className="my-4 text-2xl leading-snug tracking-tighter md:text-3xl xl:text-4xl">
              <div className="italic headings font-medium leading-tight">
                Stay Informed,
              </div>
              <div className=" font-[inital] font-bold">
                Get the Latest Updates
              </div>
            </h1>
            <h2 className="text-gray-900 font-semibolds text-[1.10rem] tracking-tight leading-7">
              Join our newsletter to receive exclusive insights, research
              findings, and industry news directly in your inbox.
            </h2>
            <div className="flex flex-wrap gap-4 justify-around lg:justify-start">
              <Button className="my-4 text-sm">Subscribe Now</Button>
            </div>
          </div>
          <div className="hidden lg:block md:max-w-[15rem] lg:max-w-sm xl:max-w-md">
            <img src={newletter} alt="" className="" />
          </div>
        </div>
      </Container>
    </div>
  );
};


export default NewsLetter;
