import React from "react";
import Layout from "../../components/layout/index";

import Hero from "../../components/home/Hero";
import Products from "../../components/home/Products";
import Leagues from "../../components/home/Leagues";
import Media from "../../components/media";
import NewsLetter from "../../components/home/Newletter";
import Blog from "../../components/home/Blog";

const Home = () => {
  return (
    <Layout>
      <Hero />
      <Products />
      <Leagues />
      <Blog />
      <NewsLetter />
      <Media />
    </Layout>
  );
};

export default Home;
