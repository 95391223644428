import React from "react";

import bg from "../../assets/backgrounds/main_grid.png";
import hero from "../../assets/assets/dashboard.png";

import Container from "../../components/layout/Container";
import Button from "../utils/Button";
// import Customers from "../customers/Customers";
import Customers from "../customers/Customers";

const HeroSec = () => {
  return (
    <div style={{ backgroundImage: `url(${bg})` }} className="opacity-100">
      <Container className="flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center gap-4 md:flex-row lg:h-[25rem] items-center">
          <div className="leading-5 lg:max-w-lg">
            <p className="my-4 text-blue-500 text-[0.70rem] font-semibold sm:text-xs">
              YOUR GATEWAY TO PRIVATE COMPANY INSIGHTS
            </p>
            <h1 className="my-4 text-2xl leading-snug tracking-tighter md:text-3xl xl:text-4xl">
              <div className=" font-medium headings leading-tight">
                Venture Intelligence,
              </div>
              <div className=" font-[inital] font-bold">
                Your Premier Source for Private Company Data in India
              </div>
            </h1>
            <h2 className="text-gray-900 font-semibolds text-[1.10rem] tracking-tight leading-7">
              Unlock opportunities, make informed decisions, and stay ahead in
              the dynamic world of private company invesments, transactions,
              financials, and valuations.
            </h2>
            <div className="flex flex-wrap gap-4 justify-around lg:justify-start">
              <Button className="my-4 text-sm">More About Us</Button>
            </div>
          </div>

          <div className="hidden lg:block lg:max-w-sm xl:max-w-sm">
            <img src={hero} alt="" className="" />
          </div>
        </div>
      </Container>
      <Customers />
    </div>
  );
};

export default HeroSec;
