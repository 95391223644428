import React from "react";

import sequoia from "../../assets/assets/customers/Sequoia_Capital_logo.png";
import accel from "../../assets/assets/customers/Accel-40-Years-Black.png";
import apollo from "../../assets/assets/customers/Apollo_Global_Management_logo.png";
import kkr from "../../assets/assets/customers/Kohlberg_Kravis_Roberts_(logo).png";
import blackstone from "../../assets/assets/customers/The_Blackstone_Group_logo.png";
import pwc from "../../assets/assets/customers/PricewaterhouseCoopers_Logo.png";
import deloitte from "../../assets/assets/customers/Deloitte (1).png";
import kpmg from "../../assets/assets/customers/kpmg-logo.png";
import standardChartered from "../../assets/assets/customers/Standard_Chartered.png";
import insead from "../../assets/assets/customers/insead.png";
import iimA from "../../assets/assets/customers/IIMA_LOGO.png";
import mdiG from "../../assets/assets/customers/MDI-Gurugon.png";
import "../media/media.css";

const Customers = () => {
  const customers = [
    {
      name: "sequoia",
      logo: sequoia,
    },
    {
      name: "accel",
      logo: accel,
    },
    {
      name: "apollo",
      logo: apollo,
    },
    {
      name: "kkr",
      logo: kkr,
    },
    {
      name: "Blackstone",
      logo: blackstone,
    },
    {
      name: "pwc",
      logo: pwc,
    },
    {
      name: "deloitte",
      logo: deloitte,
    },
    {
      name: "KPMG",
      logo: kpmg,
    },
    {
      name: "Standard Chartered",
      logo: standardChartered,
    },
    {
      name: "INSEAD",
      logo: insead,
    },
    {
      name: "IIM-A",
      logo: iimA,
    },
    {
      name: "MDI-Gurugon",
      logo: mdiG,
    },
  ];

  const animationStyle = {
    animationName: "circularMotion",
    animationDuration: "30s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
    animationDelay: "1s",
  };

  return (
    <div className="xl:w-full overflow-x-hidden">
      <p className="text-center">Trusted by customers</p>
      <div className="slider">
        <div
          className="flex items-center justify-center gap-12 ease-in-out slide-track mt-4"
          style={animationStyle}
        >
          {customers.map((customer) => (
            <img
              src={customer.logo}
              alt={customer.name}
              key={customer.name}
              className="w-20 cursor-pointer  hover:ease-in-out"
            />
          ))}
          {customers.map((customer) => (
            <img
              src={customer.logo}
              alt={customer.name}
              key={customer.name}
              className="w-20 cursor-pointer hover:ease-in-out"
            />
          ))}
        </div>
      </div>
      
    </div>
  );
};

export default Customers;
