import React from 'react';
import PropTypes from 'prop-types';
// import Button from './Button';
import TrialBtn from './TrialBtn';

const Card = ({ type, product, summary, path, image }) => {
    return (
        <div className="w-full p-4  border-solid  rounded-lg  sm:p-8    bg-white border-black    card-container ">
        <h5 className="mb-4 ml-5 text-base   text-blue-500 font-semibold">{type}</h5>
        <div className="flex items-baseline  justify-center  text-black">
            <span className="lg:text-2xl md:text-3xl text-xl font-bold tracking-tight text-[#BC8B20] ">{product}</span>
        </div>
        <ul  className="space-y-5 text-sm font-semibold lg:text-lg md:text-lg my-7 text-gray-700 ml-2 md:ml-12 lg:ml-12 md:mr-10 lg:mr-10">
            {summary}
        </ul>
        {/* <Button className="text-white    hover:text-[#F1CA75] font-semibold ml-12 mr-10   focus:rounded-lg text-lg px-5 py-2.5 inline-flex justify-center w-[120px] md:w-[180px] text-center">
            Start Trail
        </Button> */}
        <TrialBtn className="text-base ml-2 md:ml-0 lg:ml-0 md:text-lg lg:text-lg inline-flex md:justify-center w-[120px] md:w-[180px] text-center" />
        </div>
    );
};

Card.propTypes = {
    type: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
    summary: PropTypes.node.isRequired,
    path: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,  
};

export default Card;
